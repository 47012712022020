.exscroll-wrapper {
	width: 100%;
	overflow-x: scroll;
	-ms-overflow-style: none;
	scrollbar-width: none;
}

.exscroll-wrapper::-webkit-scrollbar {
	display:none;
}

.exscroll-scrollbar {
	box-sizing: border-box;
	margin-top: 20px;
	width: calc(100% - 100px);
	margin-left: 50px;
	height: 12px;
	padding: 2px;
	border-radius: 9999px;
	background-color: #fff;
	border: 1px solid #aaa;
	position: relative;
	.exscroll-track {
		overflow: hidden;
		position: relative;
		width: 100%;
		height: 100%;
	
		.exscroll-dragger {
			display: block;
			width: 40px;
			height: 100%;
			background-color: #aaa;
			border-radius: 9999px;
			position: absolute;
			top: 0;
			cursor: pointer;
		}
	}
}

